/*
 * Copyright © 2023. wrappr.
 */

import React from "react";
import TransakSDK from '@transak/transak-sdk';
import { Box, Button, useTheme } from "@mui/material";

export const TransakTest = () => {
  const theme = useTheme();

  function openTransak() {
    let transak = new TransakSDK({
      apiKey: "d30bf087-4524-4d44-92a4-6d8a7ccce4e1",
      environment: "STAGING",
      widgetHeight: "625px",
      widgetWidth: "500px",
      defaultCryptoCurrency: "ETH",
      walletAddress: "0x9bCA047dBc0a75aCB57C0D0CAeeEe937A9F6940C",
      themeColor: theme.palette.primary.main,
      email: "andreas_schaub@gmx.net",
      redirectURL: ""
    });

    transak.init();

// To get all the events
    transak.on(transak.ALL_EVENTS, (data) => {
      console.log(data)
    });

// This will trigger when the user marks payment is made.
    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
      console.log(orderData);
      transak.close();
    });
  }


  return(
    <>
      <Box>Transak</Box>
      <Button onClick={openTransak}>Open Transak Window</Button>
    </>
  )
}
